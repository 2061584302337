import { createSlice } from "@reduxjs/toolkit";
import { postApi } from "../services/postApi";

const initialState = {
  posts: [],
  reportPost: [],
};

export const postSlice = createSlice({
  name: "authInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        postApi.endpoints.getAllPosts.matchFulfilled,
        (state, { payload }) => {
          state.posts = payload.data;
        }
      )
      .addMatcher(postApi.endpoints.getAllPosts.matchRejected, (state) => {
        state.posts = [];
      });

    builder
      .addMatcher(
        postApi.endpoints.getReportPost.matchFulfilled,
        (state, { payload }) => {
          state.reportPost = payload.data;
        }
      )
      .addMatcher(postApi.endpoints.getReportPost.matchRejected, (state) => {
        state.reportPost = [];
      });
  },
});

export const {} = postSlice.actions;

export default postSlice.reducer;
