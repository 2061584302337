import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { MdBlock, MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import { SubTitle } from "../../Components/CustomComponents";
import BlockModal from "../../Components/Modals/BlockModal";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import {
  useGetReportPostQuery,
  useUpdateStatusReportPostMutation,
} from "../../Data/services/postApi";
import styles from "./styles/report_post_management.module.scss";
import Toast from "../../Hooks/useToast";

const ReportPostManagementScreen = ({ setPage }) => {
  const [editedData, setEditedData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);

  const { isLoading, refetch } = useGetReportPostQuery({});

  const reportPostData = useSelector((state) => state.post.reportPost);

  const [updateStatusReportPost, { isLoading: reportPostLoading }] =
    useUpdateStatusReportPostMutation();

  // console.log(reportPostData);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const deleteData = async (slug) => {
    await updateStatusReportPost({
      slug,
      status: "Deleted",
    });
    handleDeleteModalClose();
    Toast("Report updated successfully", "success");
    await refetch();
  };

  const blockData = async (slug) => {
    await updateStatusReportPost({
      slug,
      status: "Blocked",
    });

    handleEditModalClose();
    Toast("Report updated successfully", "success");
    await refetch();
  };

  const handleEditModalOpen = () => {
    setBlockModal(true);
  };

  const handleEditModalClose = () => {
    setBlockModal(false);
    setEditedData("");
  };

  function indication() {
    return isLoading ? "Loading..." : "Record Not Found";
  }

  const columns = [
    {
      dataField: "reason",
      text: "Reason",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "post_description",
      text: "Post Description",
      sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => row?.post?.post_description,
    },
    {
      dataField: "location",
      text: "Location",
      sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => row?.post?.location,
    },
    {
      dataField: "post_images",
      text: "Post Image",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => {
        return row?.post?.post_images?.map(({ image_url: v }) => (
          <div className="m-2">
            <img
              src={v}
              alt="Image not found"
              style={{ height: "50px", width: "50px", objectFit: "cover" }}
            />
          </div>
        ));
      },
    },
    {
      dataField: "first_name",
      text: "Full Name",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => row?.post?.Post_UserSlug?.first_name,
    },
    {
      dataField: "user_images",
      text: "User Images",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => {
        return (
          <div className="m-2">
            <img
              src={row?.post?.Post_UserSlug?.image_url}
              alt="Image not found"
              style={{ height: "30px", width: "30px" }}
            />
          </div>
        );
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-center align-items-center ">
            <div
              className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 `}
              role="button"
              onClick={() => {
                setEditedData(row);
                handleEditModalOpen(true);
              }}
            >
              <span
                className={`align-items-center d-flex justify-content-center pe-1 `}
              >
                <MdBlock />
              </span>
              <span>Block</span>
            </div>
            <div
              className="d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2 "
              role="button"
              onClick={() => {
                setEditedData(row);
                handleDeleteModalOpen();
              }}
            >
              <span className={`d-flex align-items-center pe-1`}>
                <MdDeleteForever />
              </span>
              <span>Delete</span>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal && (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
        />
      )}

      {blockModal && (
        <BlockModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          onDelete={blockData}
        />
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Report Post Management"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        <Table
          data={reportPostData || []}
          columns={columns}
          indication={indication}
        />
      </div>
    </div>
  );
};

export default ReportPostManagementScreen;
