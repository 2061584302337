import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./Data/features/userSlice";

import authSlice from "./Data/features/authSlice";

import sessionMiddleware from "./Data/middleware/sessionMiddleware";

import { userApi } from "./Data/services/userApi";

import { userAuthApi } from "./Data/services/userAuthApi";

import dashboardSlice from "./Data/features/dashboardSlice";

import { dashboardApi } from "./Data/services/dashboardApi";

import { postApi } from "./Data/services/postApi";

import postSlice from "./Data/features/postSlice";

import { staticApi } from "./Data/services/staticApi";

import staticContentSlice from "./Data/features/staticContentSlice";

export const store = configureStore({
  reducer: {
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [staticApi.reducerPath]: staticApi.reducer,
    user: userReducer,
    userAuth: authSlice,
    dashboard: dashboardSlice,
    post: postSlice,
    staticContent: staticContentSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userAuthApi.middleware,
      userApi.middleware,
      dashboardApi.middleware,
      postApi.middleware,
      staticApi.middleware,
      sessionMiddleware,
    ]),
});

//todo:
// function saveToLocalStorage(state) {
//     try {
//         const serialState = JSON.stringify(state)
//         localStorage.setItem("reduxStore", serialState)
//     } catch (e) {
//         console.warn(e);
//     }
// }

//todo:
// function loadFromLocalStorage() {
//     try {
//         const serialisedState = localStorage.getItem("reduxStore");
//         if (serialisedState === null) return undefined;
//         return JSON.parse(serialisedState);
//     } catch (e) {
//         console.warn(e);
//         return undefined;
//     }
// }

//todo:
// store.subscribe(() => saveToLocalStorage(store.getState()));

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch) //todo:
