import { createSlice } from "@reduxjs/toolkit";
import { dashboardApi } from "../services/dashboardApi";

const initialState = {
  record: {},
};

export const dashboardSlice = createSlice({
  name: "authInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        dashboardApi.endpoints.getDashboardRecord.matchFulfilled,
        (state, { payload }) => {
          state.record = payload.data;
        }
      )
      .addMatcher(
        dashboardApi.endpoints.getDashboardRecord.matchRejected,
        (state) => {
          state.record = {};
        }
      );
  },
});

export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
