import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { MdBlock, MdDeleteForever } from "react-icons/md";
import { SubTitle } from "../../Components/CustomComponents";
import BlockModal from "../../Components/Modals/BlockModal";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import { useGetSubscriptionsQuery } from "../../Data/services/postApi";
import styles from "./styles/subscription_management.module.scss";

const SubscriptionManagementScreen = ({ setPage }) => {
  const [editedData, setEditedData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);

  const { data, isLoading, refetch } = useGetSubscriptionsQuery({});

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const handleEditModalOpen = () => {
    setBlockModal(true);
  };

  const handleEditModalClose = () => {
    setBlockModal(false);
    setEditedData("");
  };

  function indication() {
    return isLoading ? "Loading..." : "Record Not Found";
  }

  const columns = [
    {
      dataField: "",
      text: "User Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            {row?.first_name} {" "} {row?.last_name}
          </div>
        );
      },
    },
    {
      dataField: "type",
      text: "Subscription Type",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "amount",
      text: "Subscription Amount",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "status",
      text: "Subscription Status",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal && (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
        />
      )}

      {blockModal && (
        <BlockModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
        />
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Report Post Management"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        <Table
          data={data?.data || []}
          columns={columns}
          indication={indication}
        />
      </div>
    </div>
  );
};

export default SubscriptionManagementScreen;
