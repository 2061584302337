import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import TextEditor from "../../Components/TextEditor/TextEditor";
import {
  useGetStaticContentQuery,
  useUpdateStaticContentMutation,
} from "../../Data/services/staticApi";
import Toast from "../../Hooks/useToast";
import styles from "./styles/StaticPage.module.scss";

const TYPE = "PRIVACY";

export default function PrivacyPolicy() {
  const [updateStaticContent, { isLoading: updateContentLoading }] =
    useUpdateStaticContentMutation();

  const { refetch } = useGetStaticContentQuery({});

  const staticContent = useSelector(
    (state) => state?.staticContent?.content?.PRIVACY
  );

  const [value, setValue] = useState("");

  const [link, setLink] = useState("");

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const result = await updateStaticContent({
        type: TYPE,
        text: value,
        link,
      }).unwrap();

      if (result?.code === 200) {
        Toast(result?.message, "success");
        refetch();
      }
    } catch (e) {
      Toast(e?.data?.message, "error");
    }
  };

  useEffect(() => {
    setValue(staticContent?.text);
    setLink(staticContent?.link);
  }, [staticContent]);

  return (
    <div className={styles.StaticPage}>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col sm={10}>
            <SubTitle text="Privacy Policy" classes="mb-4 primary-text" />
          </Col>
          <Col sm={2}>
            <Button
              classes={styles.cardBtn}
              text="Save"
              isLoading={updateContentLoading}
            />
          </Col>
        </Row>

        <Input
          name="link"
          type="text"
          value={link ? link : staticContent?.link}
          onChange={handleLinkChange}
        />

        <TextEditor
          text={value ? value : staticContent?.text}
          onChange={setValue}
        />
      </form>
    </div>
  );
}
