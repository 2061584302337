import { createSlice } from "@reduxjs/toolkit";
import { staticApi } from "../services/staticApi";

const initialState = {
  content: {},
};

export const staticContentSlice = createSlice({
  name: "staticContentSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addMatcher(
        staticApi.endpoints.getStaticContent.matchFulfilled,
        (state, { payload }) => {
          state.content = payload.data;
        }
      )
      .addMatcher(
        staticApi.endpoints.getStaticContent.matchRejected,
        (state) => {
          state.content = [];
        }
      );
  },
});

export const {} = staticContentSlice.actions;

export default staticContentSlice.reducer;
