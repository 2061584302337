import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CustomInput,
  Description,
  SubTitle,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { storeToken } from "../../Data/services/localStorageService";
import { ACCOUNT_ENUM } from "./Route";
import { useLoginUserMutation } from "../../Data/services/userAuthApi";
import Toast from "../../Hooks/useToast";
import _ from "lodash";

export default function Login({ setPage }) {
  const [loginUser, { isLoading }] = useLoginUserMutation();

  const { handleSubmit, control, register, errors } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      device_type: "android",
      device_token: "123",
    },
  });

  const navigate = useNavigate();

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleLogin = async (data) => {
    try {
      if (!data?.email || !data?.password) {
        Toast("Fields are required", "error");
        return;
      }

      const response = await loginUser(data);

      if (response?.data?.code == 200) {
        const { message, data } = response?.data;
        Toast(message, "success");
        storeToken(data?.api_token);
        navigate(WEB_ROUTES.DASHBOARD);
      } else if (!_.isEmpty(response.error)) {
        const { message } = response?.error?.data;
        Toast(message, "error");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <AccountLayout>
      <SubTitle text="Admin Panel" classes="mb-2" />

      <Form onSubmit={handleSubmit(handleLogin)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref, error } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                placeholder="Email"
                type="email"
                onChange={onChange}
                value={value}
              />
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value, ref, error } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                type="password"
                placeholder="Password"
                onChange={onChange}
                value={value}
              />
            </InputGroup>
          )}
        />

        <Button
          text={"Login"}
          isLoading={isLoading}
          classes="mt-3 mb-2"
          type="submit"
        />
      </Form>
      <Description
        classes="ms-auto cursor-pointer"
        text="Forgot Password ?"
        onClick={handleForgotPasswordClick}
      />
    </AccountLayout>
  );
}
