import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import EditUserModal from "../../Components/Modals/EditUserModal";
import Table from "../../Components/Table/Table";
import {
  useDeletePostMutation,
  useGetAllPostsQuery,
} from "../../Data/services/postApi";
import styles from "./styles/post_management.module.scss";
import Toast from "../../Hooks/useToast";

const PostManagementScreen = ({ setPage }) => {
  const [editedData, setEditedData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { isLoading, refetch } = useGetAllPostsQuery({});

  const [deletePost, { isLoading: deleteLoading }] = useDeletePostMutation();

  const userData = useSelector((state) => state.post.posts);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const deleteData = async (id) => {
    await deletePost(id);
    handleDeleteModalClose();
    Toast("Post deleted successfully", "success");
    await refetch();
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  function indication() {
    return isLoading ? "Loading..." : "Record Not Found";
  }

  const columns = [
    {
      dataField: "post_description",
      text: "Post description",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "location",
      text: "Location",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
      headerStyle: {
        width: "300px",
      },
    },
    {
      dataField: "post_images",
      text: "Post Images",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return row?.post_images?.map((v) => (
          <div className="m-2">
            <img src={v} alt="Image not found" className="w-50" />
          </div>
        ));
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-center align-items-center ">
            {/* <div
              className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 `}
              role="button"
              onClick={() => {
                setEditedData(row);
                handleEditModalOpen(true);
              }}
            >
              <span
                className={`align-items-center d-flex justify-content-center pe-1 `}
              >
                <FaUserEdit />
              </span>
              <span>Edit</span>
            </div> */}
            <div
              className="d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2 "
              role="button"
              onClick={() => {
                setEditedData(row);
                handleDeleteModalOpen();
              }}
            >
              <span className={`d-flex align-items-center pe-1`}>
                <MdDeleteForever />
              </span>
              <span>Delete</span>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal && (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isLoading={deleteLoading}
        />
      )}

      {editModal && (
        <EditUserModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
        />
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Post Management"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        <Table
          data={userData || []}
          columns={columns}
          indication={indication}
        />
      </div>
    </div>
  );
};

export default PostManagementScreen;
