import React from "react";
import { Navigate } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { getToken } from "../../Data/services/localStorageService";

function ProtectedRoute({ children }) {
  const token = getToken();
  
  return token ? children : <Navigate to={WEB_ROUTES.ACCOUNT} />;
}

export default ProtectedRoute;
