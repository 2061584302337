import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Toast from "../../Hooks/useToast";

import {
  Button,
  CustomInput,
  Description,
  SubTitle,
  UnorderedList,
} from "../../Components/CustomComponents";
import styles from "./styles/ChangePassword.module.scss";
import { useChangePasswordMutation } from "../../Data/services/userAuthApi";

export default function ChangePassword() {
  const { handleSubmit, control, register, reset } = useForm({
    mode: "onChange",
  });

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleDataSubmission = async (userData) => {
    try {
      const result = await changePassword(userData).unwrap();
      if (result?.code === 200) {
        Toast(result?.message, "success");
      }
      reset();
    } catch (error) {
      Toast(error?.data?.message, "error");
    }
  };

  return (
    <div className={styles.ChangePassword}>
      <SubTitle text="Change Password" />
      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="fs-8 mb-4"
      />
      <Form onSubmit={handleSubmit(handleDataSubmission)}>
        <div className={styles.form}>
          <Description text="Current Password" classes="mb-1" />
          <Controller
            control={control}
            name="current_password"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Current Password"
                type="password"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Description text="Set New Password" classes="mt-3 mb-1" />
          <Controller
            control={control}
            name="new_password"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="New Password"
                type="password"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="confirm_password"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Confirm Password"
                type="password"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <UnorderedList
            lists={[
              "At least 12 characters long but 14 or more is better.",
              "A combination of uppercase letters, lowercase letters, numbers, and symbols.",
            ]}
            classes="fs-6 mt-4 mb-4"
          />

          <div className={styles.saveButton}>
            <Button
              text="Change Password"
              classes="fs-6 mb-2 mt-auto"
              type="submit"
              isLoading={isLoading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
