import React from "react";
import styles from "./styles/Dashboard.module.scss";
import { Col, Row } from "react-bootstrap";
import { SubTitle } from "../../Components/CustomComponents";
import { FaUserNurse } from "react-icons/fa6";
import { GiBabyfootPlayers } from "react-icons/gi";
import { RiParentFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import DashboardCard from "../../Components/Cards/DashboardCard";
import { useGetDashboardRecordQuery } from "../../Data/services/dashboardApi";
import SpinnerLoading from "../../Components/Loading/SpinnerLoading";

export default function Dashboard({ setPage }) {
  const { isLoading } = useGetDashboardRecordQuery({});

  const dashboardRecord = useSelector((state) => state?.dashboard?.record);

  return (
    <div className={styles.Dashboard}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Dashboard"} />
        </div>
      </div>

      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<FaUserNurse color="#F75DBE" />}
            number={
              isLoading ? <SpinnerLoading /> : dashboardRecord?.post_count || 0
            }
            text={"Numbers of Posts"}
          />
        </Col>

        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<RiParentFill color="#F75DBE" />}
            number={
              isLoading ? <SpinnerLoading /> : dashboardRecord?.user_count || 0
            }
            text={"Numbers of Users"}
          />
        </Col>

        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<GiBabyfootPlayers color="#F75DBE" />}
            number={
              isLoading ? (
                <SpinnerLoading />
              ) : (
                dashboardRecord?.invoice_counts || 0
              )
            }
            text={"Numbers of Invoices"}
          />
        </Col>

        {/* <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<TbTournament color="#F75DBE" />}
            number={252}
            text={"Numbers of Tournaments"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<GiTeamDowngrade color="#F75DBE" />}
            number={252}
            text={"Numbers of Matches"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<FaUsers color="#BD4992FF" />}
            number={252}
            text={"Numbers of users"}
          />
        </Col> */}
      </Row>
    </div>
  );
}
