import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const notificationApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    sendNotification: builder.mutation({
      query: (body) => {
        return {
          url: "/send-notification",
          method: "POST",
          body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      // keepUnusedDataFor: 0,
      // refetchOnFocus: true,
    }),
  }),
});

export const { useSendNotificationMutation } = notificationApi;
