const baseRoute = (name = "") => {
  return `${process.env.REACT_APP_DOMAIN_DIR}/${name}`;
};

const apiBaseUrl = () => {
  return process.env.REACT_APP_API_DOMAIN;
};

const localStorageToken = () => {
  return process.env.REACT_APP_WEB_STORAGE_TOKEN;
};

const staticToken = () => process.env.REACT_APP_TOKEN;

export { baseRoute, apiBaseUrl, localStorageToken, staticToken };
