import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const postApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    getAllPosts: builder.query({
      query: () => {
        return {
          url: "/get-all-post",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      // keepUnusedDataFor: 0,
      // refetchOnFocus: true,
    }),
    deletePost: builder.mutation({
      query: (slug) => {
        return {
          url: `/post-delete/${slug}`,
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      // keepUnusedDataFor: 0,
      // refetchOnFocus: true,
    }),
    getReportPost: builder.query({
      query: () => {
        return {
          url: `/get-report-posts`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
    updateStatusReportPost: builder.mutation({
      query: (body) => {
        return {
          url: `/update-report-post`,
          method: "PATCH",
          body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
    getSubscriptions: builder.query({
      query: () => {
        return {
          url: `/get-subscriptions`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetAllPostsQuery,
  useDeletePostMutation,
  useGetReportPostQuery,
  useUpdateStatusReportPostMutation,
  useGetSubscriptionsQuery,
} = postApi;
