import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const userApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    updateUser: builder.mutation({
      query: (user) => {
        return {
          url: "/update-user",
          method: "PATCH",
          body: user,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
    deleteUser: builder.mutation({
      query: (slug) => {
        return {
          url: `/delete-user/${slug}`,
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
    getAllUsers: builder.query({
      query: () => {
        return {
          url: "user-list",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      // keepUnusedDataFor: 0,
      // refetchOnFocus: true,
    }),
  }),
});

export const {
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
} = userApi;
